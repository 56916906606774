import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"
import Button from "@elements/button"

const Banner = styled.div`
  ${tw`bg-[#0000FF]  mt-12 md:mt-16 2xl:mt-24`}
`
const BannerWrapper = styled.div`
  ${tw`max-w-screen-2xl mx-auto px-6 pt-10 pb-20 md:pb-10 flex flex-col gap-10 md:gap-0 md:flex-row overflow-hidden`}
`
const Headline = styled.div`
  ${tw`font-bold text-4xl text-white mb-4`}
`
const Text = styled.div`
  ${tw`text-white text-lg`}

  li {
    ${tw`relative mb-[6px] pl-8`}

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 2px;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clipPath='url(%23clip0_976_14318)'%3E%3Cpath d='M9.99965 0.5L11.3922 2.72253L13.4729 1.10317L14.0097 3.66072L16.5268 2.83954L16.1427 5.42356L18.7942 5.50017L17.5353 7.79932L20 8.76328L18.019 10.5003L20 12.2367L17.5353 13.2014L18.7942 15.4998L16.1427 15.5764L16.5268 18.1605L14.0097 17.3393L13.4729 19.8968L11.3922 18.2775L9.99965 20.5L8.6078 18.2775L6.5271 19.8968L5.99031 17.3393L3.47325 18.1605L3.85728 15.5764L1.20584 15.4998L2.46473 13.2014L0 12.2367L1.98098 10.5003L0 8.76328L2.46473 7.79932L1.20584 5.50017L3.85728 5.42356L3.47325 2.83954L5.99031 3.66072L6.5271 1.10317L8.6078 2.72253L9.99965 0.5Z' fill='%23FF00A1'/%3E%3C/g%3E%3Cpath d='M20.2363 4.05971L20.4702 3.82459L20.2363 3.58948L18.7939 2.13989L18.5576 1.90242L18.3213 2.13989L9.00448 11.5033L6.67862 9.16582L6.44232 8.92834L6.20603 9.16583L4.7637 10.6155L4.52977 10.8506L4.76371 11.0857L8.76825 15.1101L9.00454 15.3476L9.24082 15.1101L20.2363 4.05971Z' fill='%230000FF' stroke='%23FF00A1' stroke-width='0.666667'/%3E%3Cdefs%3E%3CclipPath id='clip0_976_14318'%3E%3Crect width='20' height='20' fill='white' transform='translate(0 0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
  }
`
const BannerContent = styled.div`
  ${tw`md:w-1/2`}
`
const BannerLink = styled.div`
  ${tw`md:w-1/2 flex items-center justify-center relative`}
`
const WpAcfBannerModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfBlockBannerModule
  return (
    <Banner>
      <BannerWrapper>
        <BannerContent>
          {blockData.headline && <Headline>{React.createElement(blockData.headlineTagSelector || "h3", null, parse(blockData.headline))}</Headline>}
          {blockData.text && <Text>{parse(blockData.text)}</Text>}
        </BannerContent>
        <BannerLink>
          <div className="z-50 lg:my-14">
            <Button
              buttonText="Jetzt Austauschen!"
              buttonHref={`/kontakt/`}
              buttonClasses="bg-yellow text-black font-bold uppercase text-14 2xl:text-base font-primary w-auto block py-4 px-4 md:px-2 lg:px-4 text-center"
              buttonStyle={{
                boxShadow: "3px 3px 1px #000",
              }}
            />
          </div>
          <div className="arrows-top hidden md:block absolute top-[-40px] left-[55%] -translate-x-1/2 w-[95%]">
            <svg width="592" height="112" viewBox="0 0 592 112" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: "100%", height: "auto" }}>
              <g clip-path="url(#clip0_2097_1869)">
                <path d="M372.229 65.8484L422.663 71.9363L417.657 63.7562L572.231 -30.8421L549.338 -68.2503L394.763 26.348L389.76 18.1726L372.229 65.8484Z" fill="#FF00A1" />
                <path
                  d="M422.663 71.9363L372.232 65.8531L389.763 18.1774L394.766 26.3527L549.34 -68.2456L572.231 -30.8421L417.657 63.7562L422.66 71.9316M436.679 80.9224L427.628 66.1328L582.202 -28.4655L551.758 -78.2103L397.184 16.388L388.133 1.59834L382.948 15.7021L365.417 63.3778L362.268 71.9444L371.329 73.0383L421.761 79.1215L436.679 80.9224Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip1_2097_1869)">
                <path d="M255.5 62.3274L287.019 22.4876L277.429 22.4876L277.429 -158.736L233.571 -158.736L233.571 22.4876L223.986 22.4876L255.5 62.3274Z" fill="#FF00A1" />
                <path
                  d="M287.019 22.4876L255.506 62.3275L223.992 22.4876L233.577 22.4876L233.577 -158.736L277.429 -158.736L277.429 22.4876L287.014 22.4876M302 15.2237L284.661 15.2237L284.661 -166L226.339 -166L226.339 15.2237L209 15.2237L218.323 27.0081L249.837 66.8479L255.5 74.0056L261.163 66.8479L292.677 27.0081L302 15.2237Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip2_2097_1869)">
                <path d="M142.264 66.4839L153.027 16.8368L144.416 21.0586L64.6393 -141.661L25.2598 -122.355L105.036 40.3652L96.43 44.5845L142.264 66.4839Z" fill="#FF00A1" />
                <path
                  d="M153.027 16.8368L142.269 66.4814L96.4351 44.582L105.041 40.3627L25.2649 -122.357L64.6395 -141.661L144.416 21.0586L153.022 16.8393M163.281 3.71991L147.712 11.3529L67.9351 -151.367L15.5689 -125.694L95.3453 37.0264L79.7763 44.6594L93.3353 51.1363L139.169 73.0356L147.405 76.9696L149.339 68.0498L160.097 18.4052L163.281 3.71991Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2097_1869">
                  <rect width="240" height="93" fill="white" transform="translate(591.253 -13.6759) rotate(148.534)" />
                </clipPath>
                <clipPath id="clip1_2097_1869">
                  <rect width="240" height="93" fill="white" transform="translate(302 -166) rotate(90)" />
                </clipPath>
                <clipPath id="clip2_2097_1869">
                  <rect width="240" height="93" fill="white" transform="translate(83.5042 -159) rotate(63.8828)" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="arrows-bottom absolute bottom-[-80px] md:bottom-[-40px] left-1/2 -translate-x-1/2 w-[110%]">
            <svg width="682" height="114" viewBox="0 0 682 114" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: "100%", height: "auto" }}>
              <g clip-path="url(#clip0_2097_1826)">
                <path d="M215.988 49.2043L165.366 44.9541L170.666 52.9468L19.6335 153.103L43.8721 189.654L194.905 89.4979L200.202 97.4859L215.988 49.2043Z" fill="#FF00A1" />
                <path
                  d="M165.366 44.9541L215.985 49.1997L200.199 97.4813L194.901 89.4933L43.8689 189.649L19.6334 153.103L170.666 52.9468L165.369 44.9588M151.033 36.4836L160.615 50.9344L9.5829 151.09L41.8149 199.695L192.847 99.5394L202.43 113.99L207.099 99.7072L222.885 51.4256L225.72 42.7502L216.625 41.9865L166.006 37.7409L151.033 36.4836Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip1_2097_1826)">
                <path d="M348.918 55.2853L326.583 100.912L335.954 98.8746L374.457 275.961L417.313 266.643L378.811 89.5567L388.177 87.5203L348.918 55.2853Z" fill="#FF00A1" />
                <path
                  d="M326.583 100.912L348.913 55.2865L388.171 87.5215L378.805 89.5579L417.308 266.644L374.457 275.961L335.954 98.8746L326.588 100.911M313.487 111.193L330.431 107.509L368.933 284.596L425.923 272.205L387.421 95.1183L404.364 91.4344L392.75 81.9L353.492 49.665L346.437 43.8738L342.424 52.0713L320.094 97.6969L313.487 111.193Z"
                  fill="white"
                />
              </g>
              <g clip-path="url(#clip2_2097_1826)">
                <path d="M485.632 42.2491L491.98 92.6512L498.699 85.8076L628.018 212.767L658.743 181.471L529.424 54.5114L536.139 47.6718L485.632 42.2491Z" fill="#FF00A1" />
                <path
                  d="M491.98 92.6512L485.628 42.253L536.135 47.6757L529.42 54.5153L658.739 181.475L628.018 212.767L498.699 85.8076L491.984 92.6472M486.668 108.43L498.816 96.057L628.135 223.016L668.993 181.399L539.674 54.4398L551.821 42.0666L536.88 40.4639L486.374 35.0412L477.299 34.0677L478.439 43.1233L484.791 93.5214L486.668 108.43Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2097_1826">
                  <rect width="240" height="93" fill="white" transform="translate(0 136.64) rotate(-33.55)" />
                </clipPath>
                <clipPath id="clip1_2097_1826">
                  <rect width="240" height="93" fill="white" transform="translate(351.99 288.279) rotate(-102.266)" />
                </clipPath>
                <clipPath id="clip2_2097_1826">
                  <rect width="240" height="93" fill="white" transform="translate(615.987 235.389) rotate(-135.528)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </BannerLink>
      </BannerWrapper>
    </Banner>
  )
}

WpAcfBannerModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfBannerModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfBannerModuleBlock
