import React from "react"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"

const Section = tw.section``
const Wrap = styled.div`
  ${tw`min-h-[237px] xl:min-h-[320px]`}
  max-width: 1254px;

  .headline-list-grid {
    ${tw`text-[32px] !leading-[1.17] xl:text-5xl !font-display text-left text-black mb-5`}
    &-large {
      ${tw`text-[40px] xl:text-[54px] 2xl:text-[70px] 2xl:-mt-1`}
    }
  }
  .text-list-grid {
    ${tw`text-[16px] !leading-[1.42] xl:text-2xl text-left text-black`}
    &-small {
      ${tw`text-[16px] md:text-base xl:text-xl`}
    }
    &-medium {
      ${tw`text-[18px] md:text-xl xl:text-2xl`}
    }
    &-large {
      ${tw`text-xl xl:text-2xl 2xl:text-[28px]`}
    }
  }
`

const BlogListContainer = styled.div`
  ${tw`px-skOffset mx-auto my-[30px] md:px-12 font-primary !leading-[1.42] text-lg`}
  max-width: 1254px;

  p,
  li {
    ${tw`!font-light`}
  }
  li {
    text-indent: -2rem;
    padding-left: 1.5rem;
    //use svg arrows as list bullets
    &::before {
      content: "";
      display: inline-block;
      width: 1.5rem;
      height: 1rem;
      margin-right: 0.5rem;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjMiIGhlaWdodD0iNTgiIHZpZXdCb3g9IjAgMCA2MyA1OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYzIDI5TDMzLjk2ODIgNThMMzEuMTkxNSA1NS4yMjY0TDU1LjQ3MjEgMzAuOTcyNEwwIDMwLjk3MjRMMCAyNy4wMjc2TDU1LjQ3MjEgMjcuMDI3NkwzMS4xOTE1IDIuNzczNjVMMzMuOTY4MiAwTDYzIDI5WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

`

const WpAcfBlogListGridBlock = ({ moduleData }) => {
  const module = moduleData.modulesBlogListGrid
  if (module.blocks?.length < 1) return null

  return (
    <Section>
      <BlogListContainer>
        {/* 3 column grid */}
        <Wrap className="grid grid-cols-1 mx-auto gap-x-3 gap-y-5 md:grid-cols-3">
          {module.blocks.map((block, index) => {
            return (
              <div key={`blog-list-grid-${index}`} className="px-7 md:px-5 pb-7 lg:pb-8 pt-8 bg-[#00FF00] w-full h-full flex justify-between flex-col" style={{ backgroundColor: block.color }}>
                {block.headline && <p className={`headline-list-grid headline-list-grid-${block.headlineSize}`}>{React.createElement(block.headlineTagSelector || "h3", null, parse(block.headline))}</p>}
                {block.text && <p className={`text-list-grid text-list-grid-${block.textSize}`}>{block.text}</p>}
              </div>
            )
          })}
        </Wrap>
      </BlogListContainer>
    </Section>
  )
}

export default WpAcfBlogListGridBlock
